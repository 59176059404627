<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">APOSTAMOS POR LA BAJA ROTACIÓN DEL TÉCNICO</h1>

        <p>
          Obviamente, como todo el mundo. Sin embargo, nuestra fórmula es
          <span class="secondary--text">“trata al empleado como un fin, no como un medio”</span> y dale un plan de carrera, para que mejore
          él y mejoren sus clientes.
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/bet_on_low_rotation_of_technician.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BetOnLowRotationOfTechnicians",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-betOnLowRotationOfTechnicians.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-betOnLowRotationOfTechnicians.png") + ")"
      });
    }
  }
};
</script>
